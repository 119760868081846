<template>
  <div id="resolutionPower">
    <a-form
      ref="step9Ref"
      class="ant-round-form material-resolution-power-form"
      layout="vertical"
      :model="modelForm"
      :rules="rules"
      @finish="submit"
    >

      <a-form-item name="authorizationPath" :label="$t('pages_merchant_010')">
        <div class="explain" style="margin-bottom: 24px">
          <span>{{ $t('pages_merchant_079') }}</span>
          <a v-if="language === 'en'" @click="downloadTemplate('boardResolution')">{{ $t('pages_merchant_080') }}</a>
          <a v-else @click="downloadTemplate('powerAttorney')">{{ $t('pages_merchant_080') }}</a>
        </div>
        <upload-auto
          ref="uploadRef"
          v-model:file-content="modelForm.authorizationPath"
          accept=".jpg,.jpeg,.png,.pdf,.bmp"
          type="INVITE"
          list-type="picture"
          back-type="array"
          :disabled="modelForm.authorizationPathDisabled"
          :explain="$t('pages_merchant_032')"
          :max-count="5"
        />
        <div v-if="!modelForm.authorizationPathDisabled && refused" class="error-area">
          <exclamation-circle-outlined class="ic-error" />
          <span>{{ modelForm.authorizationPathMessage }}</span>
        </div>
      </a-form-item>

      <a-form-item style="text-align: right; margin-top: 48px">
        <a-button
          type="primary"
          shape="round"
          ghost
          style="min-width: 138px; margin-right: 24px"
          @click="$emit('next', '2-5')"
        >{{ $t('pages_merchant_040') }}</a-button>
        <a-button
          html-type="submit"
          type="primary"
          shape="round"
          style="min-width: 138px"
        >{{ $t('pages_merchant_020') }}</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
<script>
import { ref, reactive, toRefs, computed, nextTick } from 'vue'
import UploadAuto from '@/components/upload-auto'
import { _user } from '@/api'
export default {
  name: 'ResolutionPower',
  components: {
    'upload-auto': UploadAuto
  },
  props: {
    model: {
      type: Object,
      default: () => { return {} }
    },
    refused: {
      type: Boolean,
      default: () => { return false }
    },
    themeData: {
      type: Object,
      default: () => { return {} }
    }
  },
  setup (props, ctx) {
    const step9Ref = ref(null)
    const uploadRef = ref(null)

    const state = reactive({})

    state.modelForm = computed(() => {
      return props.model
    })

    const submit = () => {
      ctx.emit('next', '3-1')
    }

    const downloadTemplate = async (name) => {
      const res = await _user.exportMerchantTemplate({ name })
      if (res.data.succ) window.open(res.data.data)
    }

    nextTick(() => {
      const { authorizationPath } = state.modelForm
      if (authorizationPath.length !== 0 && uploadRef.value) uploadRef.value.fileListRefresh(authorizationPath)
    })

    return {
      step9Ref,
      uploadRef,
      submit,
      downloadTemplate,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
#resolutionPower {
  .material-resolution-power {
    .explain {
      color: #495060;
    }
  }
}

</style>

