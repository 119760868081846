<template>
  <div id="merchantInfo">
    <div class="header-nav flex-align">
      <img :src="themeData.darkLogoList">
      <div class="nav-right flex-align">
        <global-outlined class="ic-lanage" />
        <language-selector style="padding-left: 10px;" />
      </div>
    </div>
    <div class="merchant-body">
      <div class="body-left">
        <a-menu
          v-model:selectedKeys="selectedKeys"
          v-model:openKeys="openKeys"
          mode="inline"
          style="height: 100%"
          @click="({ key }) => handleNext(key)"
        >
          <a-sub-menu key="1">
            <template #title>{{ $t('pages_merchant_001') }}</template>
            <template #icon><form-outlined /></template>
            <a-menu-item key="1-1">
              <template #icon>
                <close-outlined v-if="companyNameInfo.icon === 3" class="ic-error" />
                <check-outlined v-if="companyNameInfo.icon === 2" class="ic-correct" />
              </template>
              {{ $t('pages_merchant_004') }}
            </a-menu-item>
            <a-menu-item key="1-2">
              <template #icon>
                <close-outlined v-if="parentCompanyInfo.icon === 3" class="ic-error" />
                <check-outlined v-if="parentCompanyInfo.icon === 2" class="ic-correct" />
              </template>
              {{ $t('pages_merchant_005') }}
            </a-menu-item>
            <a-menu-item key="1-3">
              <template #icon>
                <close-outlined v-if="branchOfficeSubsidiary.icon === 3" class="ic-error" />
                <check-outlined v-if="branchOfficeSubsidiary.icon === 2" class="ic-correct" />
              </template>
              {{ $t('pages_merchant_006') }}
            </a-menu-item>
          </a-sub-menu>
          <a-sub-menu key="2">
            <template #title>{{ $t('pages_merchant_002') }}</template>
            <template #icon><cloud-upload-outlined /></template>
            <a-menu-item key="2-1">
              <template #icon>
                <close-outlined v-if="businessLicenseInfo.icon === 3" class="ic-error" />
                <check-outlined v-if="businessLicenseInfo.icon === 2" class="ic-correct" />
              </template>
              <span v-if="companyNameInfo.countryCode === 'CN'">{{ $t('pages_merchant_007') }}</span>
              <span v-else-if="companyNameInfo.countryCode === 'HK'">{{ $t('pages_merchant_016') }}</span>
              <span v-else>{{ $t('pages_merchant_017') }}</span>
            </a-menu-item>
            <a-menu-item v-if="!['CN', 'HK'].includes(companyNameInfo.countryCode)" key="2-2">
              <template #icon>
                <close-outlined v-if="articlesAssociation.icon === 3" class="ic-error" />
                <check-outlined v-if="articlesAssociation.icon === 2" class="ic-correct" />
              </template>
              {{ $t('pages_merchant_018') }}
            </a-menu-item>
            <a-menu-item v-if="!['CN', 'HK'].includes(companyNameInfo.countryCode)" key="2-3">
              <template #icon>
                <close-outlined v-if="annualReportInc.icon === 3" class="ic-error" />
                <check-outlined v-if="annualReportInc.icon === 2" class="ic-correct" />
              </template>
              {{ $t('pages_merchant_019') }}
            </a-menu-item>
            <a-menu-item key="2-4">
              <template #icon>
                <close-outlined v-if="identityInfo.icon === 3" class="ic-error" />
                <check-outlined v-if="identityInfo.icon === 2" class="ic-correct" />
              </template>
              {{ $t('pages_merchant_008') }}
            </a-menu-item>
            <a-menu-item key="2-5">
              <template #icon>
                <close-outlined v-if="certufucate.icon === 3" class="ic-error" />
                <check-outlined v-if="certufucate.icon === 2" class="ic-correct" />
              </template>
              {{ $t('pages_merchant_009') }}
            </a-menu-item>
            <a-menu-item key="2-6">
              <template #icon>
                <close-outlined v-if="resolutionPower.icon === 3" class="ic-error" />
                <check-outlined v-if="resolutionPower.icon === 2" class="ic-correct" />
              </template>
              {{ $t('pages_merchant_010') }}
            </a-menu-item>
          </a-sub-menu>
          <a-sub-menu key="3">
            <template #title>{{ $t('pages_merchant_003') }}</template>
            <template #icon><audit-outlined /></template>
            <a-menu-item key="3-1">
              <template #icon>
                <close-outlined v-if="sourceFundsInfo.icon === 3" class="ic-error" />
                <check-outlined v-if="sourceFundsInfo.icon === 2" class="ic-correct" />
              </template>
              {{ $t('pages_merchant_011') }}
            </a-menu-item>
            <a-menu-item key="3-2">
              <template #icon>
                <close-outlined v-if="sourceWhereabouts.icon === 3" class="ic-error" />
                <check-outlined v-if="sourceWhereabouts.icon === 2" class="ic-correct" />
              </template>
              {{ $t('pages_merchant_012') }}
            </a-menu-item>
            <a-menu-item key="3-3">
              <template #icon>
                <close-outlined v-if="otherInfo.icon === 3" class="ic-error" />
              </template>
              {{ $t('pages_merchant_128') }}
            </a-menu-item>
            <a-menu-item v-if="risked" key="3-4">
              <close-outlined v-if="eddRefuseInfo.icon === 3" class="ic-error" />
              <check-outlined v-if="eddRefuseInfo.icon === 2" class="ic-correct" />
              EDD
            </a-menu-item>
            <a-menu-item key="3-5">
              <template #icon>
                <close-outlined v-if="declareInfo.icon === 3" class="ic-error" />
                <check-outlined v-if="declareInfo.icon === 2" class="ic-correct" />
              </template>
              {{ $t('pages_merchant_013') }}
            </a-menu-item>
          </a-sub-menu>
        </a-menu>
      </div>
      <div class="body-right">
        <div style="width: 688px">
          <company-register
            v-if="selectedKeys.includes('1-1')"
            v-model:pattern="pattern"
            :model="companyNameInfo"
            :refused="refused"
            :countries="countries"
            @next="handleNext"
            @update="countryChange"
          />
          <company-parents
            v-if="selectedKeys.includes('1-2')"
            :model="parentCompanyInfo"
            :refused="refused"
            :countries="countries"
            @next="handleNext"
          />
          <company-sons
            v-if="selectedKeys.includes('1-3')"
            :model="branchOfficeSubsidiary"
            :refused="refused"
            :countries="countries"
            @next="handleNext"
          />
          <business-license
            v-if="selectedKeys.includes('2-1')"
            :model="businessLicenseInfo"
            :refused="refused"
            :country-code="companyNameInfo.countryCode"
            @next="handleNext"
          />
          <articles-association
            v-if="selectedKeys.includes('2-2')"
            :model="articlesAssociation"
            :refused="refused"
            @next="handleNext"
          />
          <annual-report-inc
            v-if="selectedKeys.includes('2-3')"
            :model="annualReportInc"
            :refused="refused"
            @next="handleNext"
          />
          <author-identity
            v-if="selectedKeys.includes('2-4')"
            :model="identityInfo"
            :refused="refused"
            :country-code="companyNameInfo.countryCode"
            @next="handleNext"
            @edit="identityChange"
          />
          <person-certificate
            v-if="selectedKeys.includes('2-5')"
            :model="certufucate"
            :refused="refused"
            :country-code="companyNameInfo.countryCode"
            :customer-identity="identityInfo.customerIdentity"
            :countries="countries"
            :refuse-attrs="refuseAttrs"
            @next="handleNext"
          />
          <resolution-power
            v-if="selectedKeys.includes('2-6')"
            :model="resolutionPower"
            :refused="refused"
            :theme-data="themeData"
            @next="handleNext"
          />
          <source-funds
            v-if="selectedKeys.includes('3-1')"
            :model="sourceFundsInfo"
            :refused="refused"
            @next="handleNext"
          />
          <source-whereabouts
            v-if="selectedKeys.includes('3-2')"
            :model="sourceWhereabouts"
            :refused="refused"
            :risked="risked"
            @next="handleNext"
          />
          <other-funds
            v-if="selectedKeys.includes('3-3')"
            :model="otherInfo"
            :refused="refused"
            :risked="risked"
            @next="handleNext"
          />
          <risk-supply
            v-if="selectedKeys.includes('3-4')"
            :model="eddRefuseInfo"
            :refused="refused"
            :countries="countries"
            @next="handleNext"
          />
          <declare-page
            v-if="selectedKeys.includes('3-5')"
            ref="submitRef"
            :model="declareInfo"
            :refused="refused"
            :risked="risked"
            :theme-data="themeData"
            @next="handleNext"
            @finish="handleFinish"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, reactive, toRefs, watch, inject } from 'vue'
import { useRouter } from 'vue-router'
import { _user, _receive, _home } from '@/api'
import { message } from 'ant-design-vue'
import i18n from '@/locale'
import CompanyRegister from './components/company-register'
import CompanyParents from './components/company-parents'
import CompanySons from './components/company-sons'
import BusinessLicense from './components/business-license'
import ArticlesAssociation from './components/articles-association'
import AnnualReportInc from './components/annual-report-inc'
import AuthorIdentity from './components/author-identity'
import PersonCertificate from './components/person-certificate'
import ResolutionPower from './components/resolution-power'
import SourceFunds from './components/source-funds'
import SourceWhereabouts from './components/source-whereabouts'
import OtherFunds from './components/other-funds'
import DeclarePage from './components/declare-page'
import RiskSupply from './components/risk-supply'
import LangSelector from '@/components/lang-sel'
export default {
  name: 'MerchantInfo',
  components: {
    'company-register': CompanyRegister,
    'company-parents': CompanyParents,
    'company-sons': CompanySons,
    'business-license': BusinessLicense,
    'articles-association': ArticlesAssociation,
    'annual-report-inc': AnnualReportInc,
    'author-identity': AuthorIdentity,
    'person-certificate': PersonCertificate,
    'resolution-power': ResolutionPower,
    'source-funds': SourceFunds,
    'source-whereabouts': SourceWhereabouts,
    'other-funds': OtherFunds,
    'declare-page': DeclarePage,
    'risk-supply': RiskSupply,
    'language-selector': LangSelector
  },
  setup () {
    const submitRef = ref(null)
    const router = useRouter()
    const state = reactive({
      themeData: inject('$themeData'),
      merchantNo: '',
      countries: [],
      risked: false,
      refused: false,
      pattern: true,
      language: localStorage.getItem('locale-language') || 'zh',
      openKeys: ['1', '2', '3'],
      selectedKeys: ['1-1'],
      refuseAttrs: [],
      companyNameInfo: {
        icon: null,
        countryCode: 'HK',
        countryCodeDisabled: false,
        countryCodeMessage: '',
        merchantNameEn: '',
        merchantNameEnDisabled: false,
        merchantNameEnMessage: ''
      },
      parentCompanyInfo: {
        icon: null,
        radioShow: '',
        radioShowDisabled: false,
        radioShowMessage: '',
        ownerShipPath: [], // 股权架构图
        ownerShipPathDisabled: false,
        ownerShipPathMessage: '',
        parentCertificatePath: [], // 注册证书及最新的incumbency
        certificatePathDisabled: false,
        certificatePathMessage: '',
        tradeCountry: [{
          country: '', // 母公司注册国家
          name: '' // 母公司注册名称
        }],
        tradeCountryDisabled: false,
        tradeCountryMessage: ''
      },
      branchOfficeSubsidiary: {
        icon: null,
        branchRadio: '',
        branchRadioDisabled: false,
        branchRadioMessage: '',
        tradeCountry: [{
          country: '', // 子公司注册国家
          name: '' // 子公司注册名称
        }],
        tradeCountryDisabled: false,
        tradeCountryMessage: ''
      },
      businessLicenseInfo: {
        icon: null,
        businessLicensePath: [], // 营业执照
        businessLicensePathDisabled: false,
        businessLicensePathMessage: '',
        businessRegistrationCertificatePath: [], // 商业登记证
        certificatePathDisabled: false,
        certificatePathMessage: '',
        incorporationPath: [], // 公司注册证明书
        incorporationPathDisabled: false,
        incorporationPathMessage: ''
      },
      articlesAssociation: {
        icon: null,
        mermorandumPath: [], // 公司章程
        mermorandumPathDisabled: false,
        mermorandumPathMessage: ''
      },
      annualReportInc: {
        icon: null,
        nar1Path1: [], // 周年年报
        nar1Path1Disabled: false,
        nar1Path1Message: '',
        nnc1Path: [], // 法团成立表
        nnc1PathDisabled: false,
        nnc1PathMessage: ''
      },
      identityInfo: {
        icon: null,
        customerIdentityDisabled: false,
        customerIdentityMessage: '',
        customerIdentity: ['3']
      },
      certufucate: {
        icon: null,
        certuFucateDisabled: false,
        customer: [{
          collDisable: false,
          certificateType: '',
          country: '',
          cert1: [],
          cert2: [],
          passport: []
        }],
        customerMessage: '',
        legalPerson: [{
          collDisable: true,
          certificateType: '',
          country: '',
          cert1: [],
          cert2: [],
          passport: []
        }],
        legalPersonMessage: '',
        directorPerson: [{
          collDisable: true,
          certificateType: '',
          country: '',
          cert1: [],
          cert2: [],
          passport: []
        }],
        directorPersonMessage: '',
        shareholder: [{
          collDisable: true,
          certificateType: '',
          country: '',
          cert1: [],
          cert2: [],
          passport: []
        }],
        shareholderMessage: ''
      },
      resolutionPower: {
        icon: null,
        authorizationPath: [], // 董事会决议/授权书
        authorizationPathDisabled: false,
        authorizationPathMessage: ''
      },
      sourceFundsInfo: {
        icon: null,
        sourceFunds: [], // 运营资金来源
        sourceFundsDisabled: false,
        sourceFundsMessage: ''
      },
      sourceWhereabouts: {
        icon: null,
        paymentPurpose: [], // 预计付款目的
        sourceWhereaboutsDisabled: false,
        sourceWhereaboutsMessage: ''
      },
      otherInfo: {
        icon: null,
        docDisabled: false,
        docMessage: '',
        docPath: [],
        supplyDisabled: false,
        supplyMessage: '',
        supplyText: ''
      },
      declareInfo: {
        icon: null,
        declareRadio: '0',
        declareRadioDisabled: false,
        declareRadioMessage: '',
        tradeCountry: [{
          country: '', // 风险交易国家
          name: '' // 风险国家公司名称
        }],
        tradeCountryDisabled: false,
        tradeCountryMessage: '',
        checkOne: false,
        checkTwo: false
      },
      eddRefuseInfo: {
        icon: null,
        customer: [{
          country: '',
          name: ''
        }],
        customerDisabled: false,
        customerMessage: '',
        supplier: [{
          country: '',
          name: ''
        }],
        supplierDisabled: false,
        supplierMessage: '',
        financialStatementsPath: [],
        statementsPathDisabled: false,
        statementsPathMessage: '',
        questionnairePath: [],
        questionnairePathDisabled: false,
        questionnairePathMessage: ''
      }
    })

    watch(
      [
        () => state.companyNameInfo,
        () => state.parentCompanyInfo,
        () => state.branchOfficeSubsidiary,
        () => state.businessLicenseInfo,
        () => state.articlesAssociation.mermorandumPath,
        () => state.annualReportInc.nnc1Path,
        () => state.identityInfo.customerIdentity,
        () => state.certufucate,
        () => state.resolutionPower.authorizationPath,
        () => state.sourceFundsInfo.sourceFunds,
        () => state.sourceWhereabouts.paymentPurpose,
        () => state.declareInfo,
        () => state.eddRefuseInfo
      ],
      (values) => {
        isFullParams(values)
      },
      { deep: true }
    )

    const handleNext = (key) => {
      state.selectedKeys = [key]
      setTemporaryValue()
    }

    const handleFinish = async () => {
      const data = {}
      const { countryCode, merchantNameEn } = state.companyNameInfo

      if (countryCode && merchantNameEn && state.pattern) {
        data.countryCode = countryCode
        data.merchantNameEn = merchantNameEn
      } else {
        return message.error(i18n.global.t('common_text_047') + i18n.global.t('pages_merchant_004'))
      }

      {
        const { radioShow, tradeCountry, ownerShipPath, parentCertificatePath } = state.parentCompanyInfo
        const flash1 = tradeCountry.some(item => (!item.country || !item.name))
        const flash2 = tradeCountry.some(item => item.country !== 'CN')
        if ((ownerShipPath.length !== 0 && ((flash2 && parentCertificatePath.length !== 0) || !flash2) && (radioShow === '1' && !flash1)) || radioShow === '0') {
          data.isParentCertificate = radioShow
          data.parentCertificatePath = parentCertificatePath
          data.ownerShipPath = ownerShipPath
          data.parentCertificate = tradeCountry.map(item => {
            return {
              parentCertificateName: item.name,
              parentCertificateCountry: item.country
            }
          })
        } else {
          return message.error(i18n.global.t('common_text_047') + i18n.global.t('pages_merchant_005'))
        }
      }

      {
        const { branchRadio, tradeCountry } = state.branchOfficeSubsidiary
        const flash = tradeCountry.some(item => (!item.country || !item.name))
        if ((branchRadio === '1' && !flash) || branchRadio === '0') {
          data.isResides = branchRadio
          data.resides = tradeCountry.map(item => {
            return {
              residesName: item.name,
              residesCountry: item.country
            }
          })
        } else {
          return message.error(i18n.global.t('common_text_047') + i18n.global.t('pages_merchant_006'))
        }
      }

      {
        const { businessLicensePath, businessRegistrationCertificatePath, incorporationPath } = state.businessLicenseInfo
        if (countryCode === 'CN' && businessLicensePath.length !== 0) {
          data.businessLicensePath = businessLicensePath
        } else if (countryCode === 'HK' && businessRegistrationCertificatePath.length !== 0) {
          data.businessRegistrationCertificatePath = businessRegistrationCertificatePath
        } else if (!['CN', 'HK'].includes(countryCode) && incorporationPath.length !== 0) {
          data.incorporationPath = incorporationPath
        } else {
          return message.error(`${i18n.global.t('common_text_047')}${countryCode === 'CN' ? i18n.global.t('pages_merchant_007') : countryCode === 'HK' ? i18n.global.t('pages_merchant_016') : i18n.global.t('pages_merchant_017')}`)
        }
      }

      {
        const { mermorandumPath } = state.articlesAssociation
        const { nar1Path1, nnc1Path } = state.annualReportInc
        data.mermorandumPath = mermorandumPath
        data.nar1Path1 = nar1Path1
        data.nnc1Path = nnc1Path
        if (!['CN', 'HK'].includes(countryCode)) {
          if (mermorandumPath.length === 0) return message.error(i18n.global.t('common_text_047') + i18n.global.t('pages_merchant_018'))
          if (nnc1Path.length === 0) return message.error(i18n.global.t('common_text_047') + i18n.global.t('pages_merchant_019'))
        }
      }

      {
        const { customerIdentity } = state.identityInfo
        if (customerIdentity.length !== 0) {
          data.customerIdentity = customerIdentity
        } else {
          return message.error(i18n.global.t('common_text_047') + i18n.global.t('pages_merchant_008'))
        }
      }

      {
        const { customer, legalPerson, directorPerson, shareholder } = state.certufucate
        const flash1 = customer.some(item => !item.certificateType || (item.certificateType === '0' && (item.cert1.length === 0 || item.cert2.length === 0)) || item.certificateType === '1' && item.passport.length === 0)
        const flash2 = legalPerson.some(item => !item.certificateType || (item.certificateType === '0' && (item.cert1.length === 0 || item.cert2.length === 0)) || item.certificateType === '1' && item.passport.length === 0)
        const flash3 = directorPerson.some(item => !item.certificateType || (item.certificateType === '0' && (item.cert1.length === 0 || item.cert2.length === 0)) || item.certificateType === '1' && item.passport.length === 0)
        const flash4 = shareholder.some(item => !item.certificateType || (item.certificateType === '0' && (item.cert1.length === 0 || item.cert2.length === 0)) || item.certificateType === '1' && item.passport.length === 0)
        if (!flash1 && (countryCode === 'CN' ? !flash2 : !flash3) && !flash4) {
          data.customer = customer.map(item => {
            return {
              customerType: item.certificateType,
              customerIssuancePlace: item.country,
              customerIDPath: item.certificateType === '0' ? item.cert1.concat(item.cert2) : item.passport
            }
          })
          if (countryCode === 'CN') {
            data.legalPerson = legalPerson.map(item => {
              return {
                legalPersonCertificateType: item.certificateType,
                legalPersonCertificateIssuancePlace: item.country,
                legalPersonCertificatePath: item.certificateType === '0' ? item.cert1.concat(item.cert2) : item.passport
              }
            })
            data.director = [{
              directorCertificateType: '',
              directorCertificateIssuancePlace: '',
              directorCertificatePath: []
            }]
          } else {
            data.director = directorPerson.map(item => {
              return {
                directorCertificateType: item.certificateType,
                directorCertificateIssuancePlace: item.country,
                directorCertificatePath: item.certificateType === '0' ? item.cert1.concat(item.cert2) : item.passport
              }
            })
            data.legalPerson = [{
              legalPersonCertificateType: '',
              legalPersonCertificateIssuancePlace: '',
              legalPersonCertificatePath: []
            }]
          }
          data.shareholder = shareholder.map(item => {
            return {
              shareholderCertificateType: item.certificateType,
              shareholderCertificateIssuancePlace: item.country,
              shareholderCertificatePath: item.certificateType === '0' ? item.cert1.concat(item.cert2) : item.passport
            }
          })
        } else {
          return message.error(i18n.global.t('common_text_047') + i18n.global.t('pages_merchant_009'))
        }
      }

      {
        const { authorizationPath } = state.resolutionPower
        const { customerIdentity } = state.identityInfo
        if (authorizationPath !== null && authorizationPath.length !== 0) {
          data.authorizationPath = authorizationPath
        } else {
          if (customerIdentity[0] === '3') {
            return message.error(i18n.global.t('common_text_047') + i18n.global.t('pages_merchant_010'))
          }
        }
      }

      {
        const { sourceFunds } = state.sourceFundsInfo
        if (sourceFunds.length !== 0) {
          data.sourceFunds = sourceFunds
        } else {
          return message.error(i18n.global.t('common_text_047') + i18n.global.t('pages_merchant_011'))
        }
      }

      {
        const { paymentPurpose } = state.sourceWhereabouts
        if (paymentPurpose.length !== 0) {
          data.paymentPurpose = paymentPurpose
        } else {
          return message.error(i18n.global.t('common_text_047') + i18n.global.t('pages_merchant_012'))
        }
      }

      {
        const { docPath, supplyText } = state.otherInfo
        data.accessories = docPath
        data.approvePurpose = supplyText
      }

      {
        const { declareRadio, tradeCountry } = state.declareInfo
        data.isRiskCountryTransaction = declareRadio
        data.riskTransaction = tradeCountry.map(item => {
          return {
            riskTransactionCompanyName: item.name,
            riskTransactionCountry: item.country
          }
        })
      }

      {
        const { financialStatementsPath, questionnairePath, customer, supplier } = state.eddRefuseInfo
        const flash1 = customer.some(item => (!item.country || !item.name))
        const flash2 = supplier.some(item => (!item.country || !item.name))
        if (state.risked && (questionnairePath.length === 0 || flash1 || flash2)) {
          return message.error(i18n.global.t('common_text_047') + 'EDD')
        } else {
          data.financialStatementsPath = financialStatementsPath
          data.questionnairePath = questionnairePath
          data.bestPerformingClient = customer.map(item => {
            return {
              bestPerformingClientName: item.name,
              bestPerformingClientCountry: item.country
            }
          })
          data.bestSupplierPartner = supplier.map(item => {
            return {
              bestPerformerSupplierPartnerName: item.name,
              bestPerformerSupplierPartnerCountry: item.country
            }
          })
        }
      }

      submitRef.value.loading = true
      const res = await _user.approveMaterial(data)
      submitRef.value.loading = false
      if (res.data.succ) {
        message.success(i18n.global.t('pages_merchant_105'))
        if (res.data.data) {
          console.log('跳转至活体提示')
          router.replace({ path: 'guide_liveness', query: { isBack: true }})
        } else {
          console.log('跳转至常规提示')
          router.replace({ path: 'guide_success', query: { isBack: true }})
        }
      } else if (res.data.resCode === '010039') {
        message.error(i18n.global.t('pages_merchant_106'))
      } else {
        message.error(res.data.resMsg)
      }
    }

    const countryChange = () => {
      state.identityInfo.customerIdentity = ['3']
      identityChange()
    }

    const identityChange = () => {
      if (state.refused) return
      const attrs = ['customer', 'legalPerson', 'directorPerson', 'shareholder']
      for (const attr of attrs) {
        state.certufucate[attr] = [{
          collDisable: attr !== 'customer',
          certificateType: '',
          country: '',
          cert1: [],
          cert2: [],
          passport: []
        }]
      }
    }

    const initPage = async () => {
      const res = await _home.getMerchantInfo()
      if (res.data.succ) {
        const { status, merchantNo } = res.data.data
        state.merchantNo = merchantNo
        if (status === 'CUSTREJ') loadMerchantInfo()
        else loadTemporaryValue()
      }
      loadAreaPub()
    }

    const loadMerchantInfo = async () => {
      const res = await _user.getRejectCustomerKyc()
      if (res.data.succ) {
        const { data } = res.data
        state.refused = true
        if (data.levelFlag === '1') state.risked = true
        dealMerchantInfo(data.merchantInfo, data.rejectFileName)
      }
    }

    const loadTemporaryValue = async () => {
      const res = await _user.getTemporaryValue({ key: state.merchantNo })
      if (res.data.succ) {
        const { data } = res.data
        if (data) {
          const info = JSON.parse(data)
          state.selectedKeys = [info.stepName || '1-1']
          state.companyNameInfo = info.companyNameInfo
          state.parentCompanyInfo = info.parentCompanyInfo
          state.branchOfficeSubsidiary = info.branchOfficeSubsidiary
          state.businessLicenseInfo = info.businessLicenseInfo
          state.articlesAssociation = info.articlesAssociation
          state.annualReportInc = info.annualReportInc
          state.certufucate = info.certufucate
          state.resolutionPower = info.resolutionPower
          state.sourceFundsInfo = info.sourceFundsInfo
          state.sourceWhereabouts = info.sourceWhereabouts
          state.declareInfo = info.declareInfo
          state.eddRefuseInfo = info.eddRefuseInfo
          if (info.otherInfo) state.otherInfo = info.otherInfo
        }
      }
    }

    const loadAreaPub = async () => {
      const res = await _receive.getAreaPub()
      if (res.data.succ) {
        state.countries = res.data.data
      }
    }

    const setTemporaryValue = () => {
      const data = {
        stepName: state.selectedKeys[0],
        merchantNo: state.merchantNo,
        companyNameInfo: state.companyNameInfo,
        parentCompanyInfo: state.parentCompanyInfo,
        branchOfficeSubsidiary: state.branchOfficeSubsidiary,
        businessLicenseInfo: state.businessLicenseInfo,
        articlesAssociation: state.articlesAssociation,
        annualReportInc: state.annualReportInc,
        resolutionPower: state.resolutionPower,
        identityInfo: state.identityInfo,
        certufucate: state.certufucate,
        sourceFundsInfo: state.sourceFundsInfo,
        sourceWhereabouts: state.sourceWhereabouts,
        declareInfo: state.declareInfo,
        eddRefuseInfo: state.eddRefuseInfo,
        otherInfo: state.otherInfo
      }
      _user.setTemporaryValue({
        key: state.merchantNo,
        value: JSON.stringify(data)
      })
    }

    const dealMerchantInfo = (merchantInfo, rejectFileName) => {
      const { countryCode, merchantNameEn, isParentCertificate, parentCertificate, ownerShipPath, parentCertificatePath,
        isResides, resides, businessLicensePath, incorporationPath, businessRegistrationCertificatePath, mermorandumPath,
        nar1Path1, nnc1Path, customerIdentity, customer, legalPerson, director, shareholder, authorizationPath, sourceFunds,
        paymentPurpose, isRiskCountryTransaction, riskTransaction, financialStatementsPath, questionnairePath, bestPerforming,
        bestPerformer, accessories, approvePurpose } = merchantInfo
      const refuseAttrs = Object.keys(rejectFileName)
      state.refuseAttrs = refuseAttrs
      state.companyNameInfo = {
        icon: (refuseAttrs.includes('countryCode') || refuseAttrs.includes('merchantNameEn')) ? 3 : 2,
        countryCode,
        countryCodeDisabled: !refuseAttrs.includes('countryCode'),
        countryCodeMessage: rejectFileName['countryCode'],
        merchantNameEn,
        merchantNameEnDisabled: !refuseAttrs.includes('merchantNameEn'),
        merchantNameEnMessage: rejectFileName['merchantNameEn']
      }
      state.parentCompanyInfo = {
        icon: (refuseAttrs.includes('isParentCertificate') || refuseAttrs.includes('parentCertificate') || refuseAttrs.includes('ownerShipPath') || refuseAttrs.includes('parentCertificatePath')) ? 3 : 2,
        radioShow: isParentCertificate,
        radioShowDisabled: !refuseAttrs.includes('isParentCertificate'),
        radioShowMessage: rejectFileName['isParentCertificate'],
        ownerShipPath,
        ownerShipPathDisabled: !refuseAttrs.includes('ownerShipPath'),
        ownerShipPathMessage: rejectFileName['ownerShipPath'],
        parentCertificatePath: parentCertificatePath,
        certificatePathDisabled: !refuseAttrs.includes('parentCertificatePath'),
        certificatePathMessage: rejectFileName['parentCertificatePath'],
        tradeCountry: parentCertificate,
        tradeCountryDisabled: !refuseAttrs.includes('parentCertificate'),
        tradeCountryMessage: rejectFileName['parentCertificate']
      }
      state.branchOfficeSubsidiary = {
        icon: (refuseAttrs.includes('isResides') || refuseAttrs.includes('resides')) ? 3 : 2,
        branchRadio: isResides,
        branchRadioDisabled: !refuseAttrs.includes('isResides'),
        branchRadioMessage: rejectFileName['isResides'],
        tradeCountry: resides,
        tradeCountryDisabled: !refuseAttrs.includes('resides'),
        tradeCountryMessage: rejectFileName['resides']
      }
      state.businessLicenseInfo = {
        icon: (refuseAttrs.includes('businessLicensePath') || refuseAttrs.includes('incorporationPath') || refuseAttrs.includes('businessRegistrationCertificatePath')) ? 3 : 2,
        businessLicensePath,
        businessLicensePathDisabled: !refuseAttrs.includes('businessLicensePath'),
        businessLicensePathMessage: rejectFileName['businessLicensePath'],
        businessRegistrationCertificatePath,
        certificatePathDisabled: !refuseAttrs.includes('businessRegistrationCertificatePath'),
        certificatePathMessage: rejectFileName['businessRegistrationCertificatePath'],
        incorporationPath,
        incorporationPathDisabled: !refuseAttrs.includes('incorporationPath'),
        incorporationPathMessage: rejectFileName['incorporationPath']
      }
      state.articlesAssociation = {
        icon: refuseAttrs.includes('mermorandumPath') ? 3 : 2,
        mermorandumPath,
        mermorandumPathDisabled: !refuseAttrs.includes('mermorandumPath'),
        mermorandumPathMessage: rejectFileName['mermorandumPath']
      }
      state.annualReportInc = {
        icon: (refuseAttrs.includes('nar1Path1') || refuseAttrs.includes('nnc1Path')) ? 3 : 2,
        nar1Path1,
        nar1Path1Disabled: !refuseAttrs.includes('nar1Path1'),
        nar1Path1Message: rejectFileName['nar1Path1'],
        nnc1Path,
        nnc1PathDisabled: !refuseAttrs.includes('nnc1Path'),
        nnc1PathMessage: rejectFileName['nnc1Path']
      }
      state.identityInfo = {
        icon: refuseAttrs.includes('customerIdentity') ? 3 : 2,
        customerIdentity,
        customerIdentityDisabled: !refuseAttrs.includes('customerIdentity'),
        customerIdentityMessage: rejectFileName['customerIdentity']
      }
      state.certufucate = {
        icon: (refuseAttrs.includes('customer') || refuseAttrs.includes('legalPerson') || refuseAttrs.includes('director') || refuseAttrs.includes('shareholder')) ? 3 : 2,
        certuFucateDisabled: !(refuseAttrs.includes('customer') || refuseAttrs.includes('legalPerson') || refuseAttrs.includes('director') || refuseAttrs.includes('shareholder')),
        customer: customer.map(item => {
          return {
            certificateType: item.type,
            country: item.issuancePlace,
            cert1: (item.type === '0' && item.path[0]) ? [item.path[0]] : [],
            cert2: (item.type === '0' && item.path[1]) ? [item.path[1]] : [],
            passport: (item.type === '1' && item.path.length !== 0) ? item.path : []
          }
        }),
        customerMessage: rejectFileName['customer'],
        legalPerson: legalPerson.map(item => {
          return {
            certificateType: item.type,
            country: item.issuancePlace,
            cert1: (item.type === '0' && item.path[0]) ? [item.path[0]] : [],
            cert2: (item.type === '0' && item.path[1]) ? [item.path[1]] : [],
            passport: (item.type === '1' && item.path.length !== 0) ? item.path : []
          }
        }),
        legalPersonMessage: rejectFileName['legalPerson'],
        directorPerson: director.map(item => {
          return {
            certificateType: item.type,
            country: item.issuancePlace,
            cert1: (item.type === '0' && item.path[0]) ? [item.path[0]] : [],
            cert2: (item.type === '0' && item.path[1]) ? [item.path[1]] : [],
            passport: (item.type === '1' && item.path.length !== 0) ? item.path : []
          }
        }),
        directorPersonMessage: rejectFileName['director'],
        shareholder: shareholder.map(item => {
          return {
            certificateType: item.type,
            country: item.issuancePlace,
            cert1: (item.type === '0' && item.path[0]) ? [item.path[0]] : [],
            cert2: (item.type === '0' && item.path[1]) ? [item.path[1]] : [],
            passport: (item.type === '1' && item.path.length !== 0) ? item.path : []
          }
        }),
        shareholderMessage: rejectFileName['shareholder']
      }
      state.resolutionPower = {
        icon: refuseAttrs.includes('authorizationPath') ? 3 : 2,
        authorizationPath,
        authorizationPathDisabled: !refuseAttrs.includes('authorizationPath'),
        authorizationPathMessage: rejectFileName['authorizationPath']
      }
      if (!state.identityInfo.customerIdentityDisabled) {
        state.resolutionPower.authorizationPathDisabled = state.identityInfo.customerIdentityDisabled
        state.resolutionPower.authorizationPathMessage = state.identityInfo.customerIdentityMessage
      }
      state.sourceFundsInfo = {
        icon: refuseAttrs.includes('sourceFunds') ? 3 : 2,
        sourceFunds,
        sourceFundsDisabled: !refuseAttrs.includes('sourceFunds'),
        sourceFundsMessage: rejectFileName['sourceFunds']
      }
      state.sourceWhereabouts = {
        icon: refuseAttrs.includes('paymentPurpose') ? 3 : 2,
        paymentPurpose,
        sourceWhereaboutsDisabled: !refuseAttrs.includes('paymentPurpose'),
        sourceWhereaboutsMessage: rejectFileName['paymentPurpose']
      }
      state.otherInfo = {
        icon: (refuseAttrs.includes('approvePurpose') || refuseAttrs.includes('accessories')) ? 3 : null,
        docDisabled: !refuseAttrs.includes('accessories'),
        docMessage: rejectFileName['accessories'],
        docPath: accessories,
        supplyDisabled: !refuseAttrs.includes('approvePurpose'),
        supplyMessage: rejectFileName['approvePurpose'],
        supplyText: approvePurpose
      }
      state.declareInfo = {
        icon: (refuseAttrs.includes('isRiskCountryTransaction') || refuseAttrs.includes('riskTransaction')) ? 3 : 2,
        declareRadio: isRiskCountryTransaction,
        declareRadioDisabled: !refuseAttrs.includes('isRiskCountryTransaction'),
        declareRadioMessage: rejectFileName['isRiskCountryTransaction'],
        tradeCountry: riskTransaction,
        tradeCountryDisabled: !refuseAttrs.includes('riskTransaction'),
        tradeCountryMessage: rejectFileName['riskTransaction'],
        checkOne: false,
        checkTwo: false
      }
      state.eddRefuseInfo = {
        icon: (refuseAttrs.includes('financialStatementsPath') || refuseAttrs.includes('questionnairePath') || refuseAttrs.includes('bestPerforming') || refuseAttrs.includes('bestPerformer')) ? 3 : 2,
        customer: bestPerforming,
        customerDisabled: !refuseAttrs.includes('bestPerforming'),
        customerMessage: rejectFileName['bestPerforming'],
        supplier: bestPerformer,
        supplierDisabled: !refuseAttrs.includes('bestPerformer'),
        supplierMessage: rejectFileName['bestPerformer'],
        financialStatementsPath,
        statementsPathDisabled: !refuseAttrs.includes('financialStatementsPath'),
        statementsPathMessage: rejectFileName['financialStatementsPath'],
        questionnairePath,
        questionnairePathDisabled: !refuseAttrs.includes('questionnairePath'),
        questionnairePathMessage: rejectFileName['questionnairePath']
      }
    }

    const isFullParams = (values) => {
      if (!state.refused) {
        const { countryCode } = values[0]
        const { radioShow, tradeCountry, ownerShipPath, parentCertificatePath } = values[1]
        const { businessLicensePath, businessRegistrationCertificatePath, incorporationPath } = values[3]
        const { customer, legalPerson, directorPerson, shareholder } = values[7]

        state.companyNameInfo.icon = values[0].merchantNameEn && state.pattern ? 2 : null

        state.businessLicenseInfo.icon = ((countryCode === 'CN' && businessLicensePath.length !== 0) ||
          (countryCode === 'HK' && businessRegistrationCertificatePath.length !== 0) ||
          (!['CN', 'HK'].includes(countryCode) && incorporationPath.length !== 0)) ? 2 : null

        state.articlesAssociation.icon = values[4].length !== 0 ? 2 : null

        state.annualReportInc.icon = values[5].length !== 0 ? 2 : null

        state.identityInfo.icon = values[6].length !== 0 ? 2 : null

        state.resolutionPower.icon = values[8].length !== 0 ? 2 : null

        state.sourceFundsInfo.icon = values[9].length !== 0 ? 2 : null

        state.sourceWhereabouts.icon = values[10].length !== 0 ? 2 : null

        {
          const flash1 = tradeCountry.some(item => (!item.country || !item.name))
          const flash2 = tradeCountry.some(item => item.country !== 'CN')
          state.parentCompanyInfo.icon = ((ownerShipPath.length !== 0 &&
            ((flash2 && parentCertificatePath.length !== 0) || !flash2) &&
            (radioShow === '1' && !flash1)) || radioShow === '0') ? 2 : null
        }

        {
          const flash = values[2].tradeCountry.some(item => (!item.country || !item.name))
          state.branchOfficeSubsidiary.icon = ((values[2].branchRadio === '1' && !flash) || values[2].branchRadio === '0') ? 2 : null
        }
        {
          const flash1 = customer.some(item => !item.certificateType || (item.certificateType === '0' && (item.cert1.length === 0 || item.cert2.length === 0)) || item.certificateType === '1' && item.passport.length === 0)
          const flash2 = legalPerson.some(item => !item.certificateType || (item.certificateType === '0' && (item.cert1.length === 0 || item.cert2.length === 0)) || item.certificateType === '1' && item.passport.length === 0)
          const flash3 = directorPerson.some(item => !item.certificateType || (item.certificateType === '0' && (item.cert1.length === 0 || item.cert2.length === 0)) || item.certificateType === '1' && item.passport.length === 0)
          const flash4 = shareholder.some(item => !item.certificateType || (item.certificateType === '0' && (item.cert1.length === 0 || item.cert2.length === 0)) || item.certificateType === '1' && item.passport.length === 0)
          state.certufucate.icon = (!flash1 && (countryCode === 'CN' ? !flash2 : !flash3) && !flash4) ? 2 : null
        }
        {
          const { declareRadio, tradeCountry, checkOne, checkTwo } = values[11]
          const flash = tradeCountry.some(item => (!item.country || !item.name))
          state.declareInfo.icon = (declareRadio === '0' || (declareRadio === '1' && !flash)) && checkOne && checkTwo ? 2 : null
        }
        if (!state.risked) return
        {
          const { questionnairePath, customer, supplier } = values[12]
          const flash1 = customer.some(item => (!item.country || !item.name))
          const flash2 = supplier.some(item => (!item.country || !item.name))
          state.eddRefuseInfo.icon = (questionnairePath.length !== 0 && !flash1 && !flash2) ? 2 : null
        }
      }
    }

    initPage()

    return {
      submitRef,
      handleNext,
      handleFinish,
      countryChange,
      identityChange,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
#merchantInfo {
  text-align: left;
  .header-nav {
    height: 60px;
    padding: 0 24px;
    margin-bottom: 4px;
    box-shadow: 0 0 6px 0 #d9d9d9;
    img {
      width: 150px;
    }
    .nav-right {
      margin-left: auto;
      cursor: pointer;
      .ic-lanage {
        color: @main-color;
        font-size: 24px;
      }
      .text {
        margin-left: 8px;
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
  .merchant-body {
    display: flex;
    .body-left {
      width: 333px;
      height: calc(100vh - 60px);
      .ic-error {
        color: @error-color;
      }
      .ic-correct {
        color: @success-color;
      }
    }
    .body-left:deep(.ant-menu-inline) {
      background: #fff;
    }
    .body-right {
      .scrollbar-none;
      width: calc(100% - 152px);
      height: calc(100vh - 60px);
      padding: 24px 0 24px 128px;
      overflow: scroll;
    }
    .body-right::-webkit-scrollbar {
      display: none;
    }
    .body-right:deep(.error-area) {
      display: flex;
      padding: 12px 24px;
      border-radius: 5px;
      color: @error-color;
      background: #fef0f0;
      margin-top: 10px;
      text-align: left;
      word-break: break-word;
      .ic-error {
        font-size: 28px;
        margin-right: 12px;
      }
    }
    .body-right:deep(.ant-form-item-label) {
      label {
        font-size: 16px;
      }
    }
  }
}
</style>
